/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import Masonry from 'masonry-layout';

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	document.documentElement.style.setProperty('--hh', `${document.documentElement.clientHeight + 3}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine',
	duration: 300
});

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '2560px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

//Default slider
document.querySelectorAll('.slider-block').forEach(function (elem) {
	var slider = elem.querySelector('.swiper-container');
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var swiper = new Swiper(slider, {
			slidesPerView: '1',
			spaceBetween: 48,
			breakpoints: {
				// when window width is >= 1024px
				750: {
					slidesPerView: 2
				}
			},
			scrollbar: {
				el: ".swiper-scrollbar",
				hide: false,
				dragSize: 56
			}
		});
	})();
});

//Blog slider
document.querySelectorAll('.blog-block').forEach(function (elem) {
	var slider = elem.querySelector('.swiper-container');
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var swiper = new Swiper(slider, {
			slidesPerView: '1',
			spaceBetween: 48,
			breakpoints: {
				// when window width is >= 1024px
				750: {
					slidesPerView: 2
				},
				1440: {
					slidesPerView: 'auto'
				}
			},
			scrollbar: {
				el: ".swiper-scrollbar",
				hide: false,
				dragSize: 56
			}
		});
	})();
});

//Experiences slider
document.querySelectorAll('.experiences-block').forEach(function (elem) {
	var slider = elem.querySelector('.swiper-container');
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var sliderNext = elem.querySelector('.swiper-btn-next');
		var sliderPrev = elem.querySelector('.swiper-btn-prev');
		var swiper = new Swiper(slider, {
			slidesPerView: '1',
			spaceBetween: 48,
			scrollbar: {
				el: ".swiper-scrollbar",
				hide: false,
				dragSize: 56
			},
			navigation: {
				nextEl: sliderNext,
				prevEl: sliderPrev,
			},
		});
	})();
});

var overlayContainers = document.querySelectorAll('.modal-container');
overlayContainers.forEach(function (btn) {
	btn.addEventListener("click", function (e) {
		var linkedModalOverlay = btn.querySelector('.overlay');
		document.querySelectorAll('.overlay').forEach(function (overlay) {
			if (!overlay.classList.contains("closed") && overlay !== linkedModalOverlay) {
				overlay.classList.add("closed");
			}
		});

		if (linkedModalOverlay.classList.contains("closed")) {
			linkedModalOverlay.classList.remove("closed");
		}
		else {
			linkedModalOverlay.classList.add("closed");
		}
	});
});

// Close dropdowns on escape key
var dropdowns = document.querySelectorAll('.dropdown-checkbox').forEach(function (dropdown) {
	if (dropdown) {

		const toggle = checked => {
			dropdown.checked = checked;
		};

		document.addEventListener('keydown', e => {
			if (e.keyCode === 27 && dropdown.checked) {
				toggle(false);
			}
		});

		document.addEventListener('click', e => {
			if (dropdown.checked && e.target !== dropdown && !(e.target.classList.contains("dropdown-label"))) {
				console.log(e.target);
				toggle(false);
			}
		});

		// Keep aria-expanded in-sync with checked state
		dropdown.addEventListener('change', () => {
			toggle(dropdown.checked);
		});

		toggle(dropdown.checked);
	}
});

// Load more buttons
var displayAmount = 0;
var loadMoreAmount = 0;
var overview = "";
var requestData = "";

// Blog Masonry
var blogMasonry = document.querySelector('.blogs-overview');
if (blogMasonry) {
	var blogMasonryColumns = blogMasonry.querySelector('.masonry-columns');
	var blogMsnry = new Masonry(blogMasonryColumns, {
		// options
		itemSelector: '.masonry-column',
		percentPosition: true
	});

	//Blog load more
	var loadMoreBlogs = document.querySelector('.load-blogs-btn');
	if (loadMoreBlogs) {
		displayAmount = 4;
		loadMoreAmount = 4;
		overview = document.querySelector('.blogs');

		loadMoreBlogs.addEventListener("click", async function (e) {
			loadMore(e, loadMoreBlogs, displayAmount, loadMoreAmount, overview, requestData, blogMsnry, blogMasonryColumns);
			displayAmount += loadMoreAmount;
			e.preventDefault();
		});
	}
}

// Experiences Masonry
var experiencesMasonry = document.querySelector('.experiences-overview');
if (experiencesMasonry) {
	var experiencesMasonryColumns = experiencesMasonry.querySelector('.masonry-columns');
	var experiencesMsnry = new Masonry(experiencesMasonryColumns, {
		// options
		itemSelector: '.masonry-column',
		percentPosition: true
	});

	//Experiences load more
	var loadMoreExperiences = document.querySelector('.load-experiences-btn');
	if (loadMoreExperiences) {
		displayAmount = 12;
		loadMoreAmount = 6;
		overview = document.querySelector('.experiences');

		loadMoreExperiences.addEventListener("click", async function (e) {
			loadMore(e, loadMoreExperiences, displayAmount, loadMoreAmount, overview, requestData, experiencesMsnry, experiencesMasonryColumns);
			displayAmount += loadMoreAmount;
			e.preventDefault();
		});
	}
}

function loadMore(e, loadMoreBtn, displayAmount, loadMoreAmount, overview, requestData, msnry, msnryColumns) {
	var maxAmount = e.target.id.split("-")[1];
	if (parseInt(displayAmount) >= (parseInt(maxAmount) - loadMoreAmount)) {
		loadMoreBtn.classList.add("hidden");
	}

	// Get new overview
	fetch(window.location.pathname, {
		method: "post",
		data: requestData,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'Display-Amount': displayAmount
		}
	}).then(response => response.text()).then(data => {
		// Create DOM
		var parser = new DOMParser();
		var doc = parser.parseFromString(data, 'text/html');

		// Add items with animation
		doc.querySelectorAll('.column').forEach(function (child, i) {
			overview.appendChild(child);
		});

		msnry = new Masonry(msnryColumns, {
			// options
			itemSelector: '.masonry-column',
			percentPosition: true
		});
	});
};

// Jump to submitted form
document.querySelectorAll('.form-block').forEach(el => {
	var form = el.querySelector('form'),
		button = el.querySelector('.button');

	if (form) {

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});

		var anchor = document.querySelector('footer').getAttribute('id');
		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);
	}
});

// Close menu on escape key
const navigationToggler = document.getElementById('nav');
if (navigationToggler) {

	const toggle = checked => {
		navigationToggler.checked = checked;
	};

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	document.addEventListener('click', e => {
		if (navigationToggler.checked === true) {
			if (e.target.classList.contains("nav-backdrop")) {
				toggle(false);
			}
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}